<!-- 설비 유형 상세 (EquipTypeDetail) -->
<template>
    <div class="detail-container">

        <!-- 1. 데이터 없음 표시 영역 -->
        <div class="detail-placeholder" v-if="!detailInfo">
            <span class="detail-notification-icon">
                <i class="fas fa-external-link-alt"></i>
            </span>
            <span class="detail-notification-text">계통유형코드를 선택하세요.</span>
        </div>

        <!-- 2.상세 정보 영역 -->
        <div style="max-height:calc(100% - 70px)" v-if="detailInfo">
            <!-- 2.1 상세 정보 헤더 영역 -->
            <div class="detail-header" v-if="detailInfo">
                <div class="detail-title">
                    <span class="title">{{ viewTitle }}</span>
                    <span class="badge title1"> {{ detailInfo.sysType }} </span>
                    <span class="badge title2"> {{ detailInfo.sysTypeName }} </span>
                </div>
                <div class="detail-control" v-if="!isNewMode">
                    <b-form-checkbox switch v-model="isEditMode" size="lg" />
                </div>
            </div>

            <!-- 2.2 상세 정보 컨텐츠 표시 영역 -->
            <div class="detail-content" v-if="detailInfo">
                <BaseInfoPanel :detailInfo="detailInfo" :mode="mode" />
            </div>
        </div>

        <!-- 3.하단 영역 -->
        <div class="detail-bottom">
            <!-- 3.1 자료 갱신 정보  -->
            <div class="detail-bottom-info" v-if="detailInfo">
                <span>{{ `작성자: ${detailInfo.regWorker ? detailInfo.regWorker : "-"}` }}</span>
                <span>{{ `작성일자: ${detailInfo.regDate ? detailInfo.regDate : "-"}` }}</span>
                <span>{{ `수정일자: ${detailInfo.modDate ? detailInfo.modDate : "-"}` }}</span>
            </div>
            <!-- 3.2 하단 제어 버튼 -->
            <div class="detail-bottom-buttons">
                <button type="button" class="btn btn-primary detail-btn" v-if="isEditMode"
                    @click="handleSave(detailInfo, isEditMode)">저장</button>
                <button type="button" class="btn btn-secondary detail-btn" v-if="detailInfo"
                    @click="handleClose($event)">닫기</button>
            </div>
        </div>

    </div>
</template>

<script>
import BaseInfoPanel from './panels/BaseInfoPanel.vue';
// import AllowedEquipTypePanel from './panels/AllowedEquipTypePanel.vue';

import backEndApi from "@src/api/backEndApi";
import moment from "moment";

export default {
    components: {
        BaseInfoPanel,
        // AllowedEquipTypePanel
    },
    props: ['title', 'sysType'],
    async created() { },
    mounted() {
        console.log('SystemTypeDetail.mounted() called')
    },
    data() {
        return {
            detailInfo: null,
            mode: 'view',            // 편집모드 : edit/view
        };
    },
    computed: {
        isEditMode: {
            get() {
                return this.mode == 'edit' || this.mode == 'new';
            },
            set(value) {
                this.mode = value ? 'edit' : 'view';
            },
        },
        isNewMode() {
            return this.mode === 'new';
        },
        isViewMode() {
            return this.mode === 'view';
        },
        viewTitle() {
            return this.title;
        },
    },
    watch: {
        sysType() {
            console.log('SystemTypeDetail sysType is changed:', this.sysType);

            if (!this.sysType) {
                // clear detailInfo
                this.clearData();
            } else {
                // load detailInfo
                this.loadData();
            }
        }
    },
    methods: {                
        async notifyCancelEdit() {
            // return true : Edit Cancel & Proceed Next Step
            //        false: Stop.
            if (this.isEditMode) {
                const result = await this.alertConfirmWarning("편집중인 내용을 무시하고 종료할까요?");
                // console.log('alertConfirmWarning() result : ', result);
                if (result.value) return true;
                else return false;
            }

            return true;
        },

        // list-Detail Data Functions
        newData() {
            this.mode = 'new';
            this.detailInfo = {
                sysType: "",
                sysTypeName: "",
                enabled: "Y",
                description: "",
                icon: "fas fa-sitemap",
                allowedEquipTypes: [],
                action: '',
            };

            return this.detailInfo;
        },

        async loadData() {
            console.log('SysTypeDetail loadData() is called. ', this.sysType);

            try {
                let result = await backEndApi.sysType.getSysType(this.sysType);

                result.data.regDate = result.data.regDate ? moment(result.data.regDate).format("YYYY-MM-DD HH:mm:ss") : null;
                result.data.modDate = result.data.modDate ? moment(result.data.modDate).format("YYYY-MM-DD HH:mm:ss") : null;

                if (result.status == 200) {
                    this.detailInfo = { ...result.data };
                    this.mode = 'view';
                    this.detailInfo.allowedEquipTypes.forEach(item => {
                        let foundType = this.$store.state.equipTypeList.find(type => type.equipType == item.equipType)
                        item.equipTypeName = foundType.equipTypeName;
                    })
                }

                console.log('SysTypeDetail loadData.', this.detailInfo);
            } catch (e) {
                this.alertNoti("데이터를 조회할 수 없습니다.");
                console.error(e);
            }
        },

        clearData() {
            console.log('SysTypeDetail clearData() is called.');

            this.detailInfo = null;
            this.mode = "view";

            this.$forceUpdate();
        },

        async saveData(data) {
            console.log('SysTypeDetail saveData() is called.', data)

            if (this.isEmpty(data.sysType)) {
                this.alertNoti("공통 코드는 필수 입력 항목입니다.");
                return;
            }
            if (this.isEmpty(data.sysTypeName)) {
                this.alertNoti("공통 코드명는 필수 입력 항목입니다.");
                return;
            }
            data.allowedEquipTypes.forEach(item => {
                delete item.equipTypeName;
            });

            try {
                let result = null;

                result = await backEndApi.sysType.saveSysType(data);
                if (result.status == 200) {
                    await this.alertNoti("데이터를 저장하였습니다..");

                    if (this.isNewMode) this.$emit('data-updated', 'insert', this.detailInfo);
                    else this.$emit('data-updated', 'update', this.detailInfo);

                    this.mode = 'view';
                }
            } catch (e) {
                this.alertNoti("데이터를 저장할 수 없습니다.");
                console.error(e);
            }
        },

        async deleteData() {
            const result = await this.alertConfirmWarning("현재 선택된 데이터를 삭제할까요?");
            // console.log('alertConfirmWarning() result : ', result);
            if (!result.value) return;

            const deletedSysType = this.detailInfo.sysType;

            console.log('SysTypeDetail deleteData() is called. data: ', deletedSysType);

            try {
                const sysType = [deletedSysType];
                const result = await backEndApi.sysType.deleteSysType(sysType);
                console.log('SysTypeDetail deleteData.', result);

                this.clearData();
                this.$emit('data-updated', 'delete', deletedSysType);

                this.alertNoti("데이터를 삭제하였습니다.");
            } catch (e) {
                this.alertNoti("데이터를 삭제할 수 없습니다.");
                console.error(e);
            }
        },

        getData() {
            return this.detailInfo;
        },

        async handleSave(info) {
            console.log('SysTypeDetail handleSave() is called.', info)
            if(this.isNewMode) info.action = "create";
            else info.action = "update";
            await this.saveData(info);
        },

        async handleClose(event) {
            console.log('SysTypeDetail handleClose() is called')

            const result = await this.notifyCancelEdit();
            if (!result) {
                event && event.preventDefault();
                return false;
            }
            // this.toggleEditMode();  
            this.clearData();
            this.$emit('closed', this.sysType);

            return true;
        },
    },
}

</script>

<style scoped>

/* detail area styles */
.detail-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.detail-notification-text {
    font-size: 15px;
    font-weight: bold;
}

.detail-container {
    height: 100%;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    
    border-radius: 10px;
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-header {
    margin-bottom: 0px !important;
    padding: 8px 16px 8px 16px;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-weight: bold;
    border-radius: 6px 6px 0px 0px;
    background-color: #f1f1f1;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
}

.detail-header .detail-title {
    padding: 0 !important;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.detail-content {
    height: Calc(100% - 50px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 2px;
    padding: 20px;
    overflow: hidden;
    background-color: white;
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
}

.detail-itemview {
    /* height: 200px; */
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    /* border: solid red 1px; */
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 1rem; */
}

.detail-control {
    margin-bottom: 8px !important;
    display: flex;
    justify-content: flex-end;
}

.detail-menu {
    font-size: 0.8rem;
    font-weight: bold;
}

.detail-bottom {
    margin-top: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40 px;
}

.detail-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 8px;
}

.detail-btn {
    /* height: 50px; */
    flex: 1;
    margin: 0px 4px 0px 4px;
}

.detail-bottom-info {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 8px 4px 8px;
}

/* input form styles */
.title {
    /* font-size: 14px; */
    font-weight: 600;
    margin-right: 20px;
}

.title-icon {
    font-size: 1rem !important;
}

.input-form {
    width: 100%;
    /* 부모 요소에 꽉 맞춤 */
    box-sizing: border-box;
    /* 입력 폼 요소의 패딩과 보더를 포함하여 크기를 계산 */
}

.input-form input {
    width: 100%;
    /* 입력 요소가 부모 요소에 꽉 맞춤 */
    padding: 10px;
    /* 선택적으로 패딩 설정 */
    margin-bottom: 10px;
    /* 선택적으로 아래쪽 여백 추가 */
}

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
    display: none;
}

/* Badge styles */
.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #FF6D00;
    color: #fff;
}

.badge.title2 {
    background-color: #546E7A;
    color: #fff;
}

.badge.others {
    background-color: #CFD8DC;
    color: #000;
}

/* Status Button Style */
.status-icon {
    font-size: 16px;
    color: #546E7A;
    background-color: #ECEFF1;
    border: solid #B0BEC5 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    /* color: #546E7A; */
    background-color: #B0BEC5;
}

.status-icon.active {
    color: #ECEFF1;
    background-color: #546E7A;
}
</style>