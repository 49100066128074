<template>
    <div class="detail-container">
        <div class="detail-content">
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-8">
                    <label for="equipType">설비유형</label>
                    <select v-model="detailInfo.equipType" id="equipType" class="form-control" :disabled="!isEditMode"
                        @change="selectEquipType">
                        <option value=null>선택</option>
                        <option v-for="(equip, index) in equipData" :key="index" :value="equip.equipType">
                            {{ `${equip.equipTypeName}(${equip.equipType})` }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4">
                    <label for="reltnType">관계유형</label>
                    <select v-model="detailInfo.reltnType" id="reltnType" class="form-control" :disabled="!isEditMode">
                        <option value=null>선택</option>
                        <option v-for="code in $store.state.commonCodes.equipReltnType" :key="code.value"
                            :value="code.text">
                            {{ `${code.value}(${code.text})` }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['mode'],
    components: {
    },
    data() {
        return {
            detailInfo: null,
            equipData: [],
        }
    },
    // watch: {
    //     data() {
    //         console.log('WATCH data is changed. data: ', this.data);
    //         this.detailInfo = this.data;
    //     }
    // },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
    },
    created() {
        this.detailInfo = this.initData();
        this.getEquipType();
    },
    mounted() {
    },
    destroyed() { },
    methods: {
        selectEquipType() {
            if (this.detailInfo.equipType) {
                let found = this.equipData.find(item => item.equipType == this.detailInfo.equipType);
                this.detailInfo.equipTypeName = found.equipTypeName;
            }
        },
        async getEquipType() {
            if (this.$store.state.equipTypeList) {
                this.$store.state.equipTypeList.forEach(item => {
                    this.equipData.push({
                        equipType: item.equipType,
                        equipTypeName: item.equipTypeName
                    })
                })
            }
        },
        initData() {
            return {
                equipType: null,
                equipTypeName: null,
                reltnType: "include",
            }
        },
        setData(data) {
            if (data) {
                // this.mode = 'view';
                this.detailInfo = data;
            } else {
                // this.mode = 'edit';
                this.detailInfo = this.initData();
            }
        },
        getData() {
            return this.detailInfo;
        },
    },
}
</script>

<style scoped>
.detail-container {
    height: 100%;
    /* width: 100%; */
    border-radius: 8px;
    border: solid #eee 1px;
    /* background-color: white; */
    /* margin: 1rem; */
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}
</style>