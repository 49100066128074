<template>
    <div class="ld-container" style="height: 90vh; width: 100%">
        <div class="row" style="height:100%;">
            <div class="list-area col-md-6">
                <div class="row">
                    <header-box :title="`${$t('시스템 관리')} > ${$t('계통 및 설비')} > ${$t('계통유형코드 관리')}`" />
                </div>
                <div style="height: calc(100% - 40px)">
                    <table-list ref="table-list" :title="'계통유형코드'" :columns="columns" :rows="sysTypeList"
                        :keyField="'sysType'" :toggleFields="['enabled']" :transCodes="transCodes" :controls="controls"
                        :useMaxHeight="true" @button-click="handleListButtonClick" :mode="'edit'"
                        @row-click="handleItemClick" @toggle-changed="handleItemToggle" />
                </div>
            </div>
            <div class="detail-area col-md-6 ">
                <SystemTypeDetail ref="SystemTypeDetail" :title="'계통유형코드'" :sysType="sysType"
                    @data-updated="handleDetailUpdated" @closed="handleDetailClosed" />
            </div>
        </div>
    </div>
</template>

<script>
import headerBox from "@views/component/headerBox/analysis/Header.vue";
import TableList from "@src/views/component/v2.1/ListDetailView/TableList.vue";

import SystemTypeDetail from "./SystemTypeDetail.vue"
import backEndApi from "@src/api/backEndApi";

// import moment from "moment"

export default {
    components: {
        headerBox,
        TableList,
        SystemTypeDetail
    },
    props: {},
    data() {
        return {
            sysType: null,
            controls: [
                { event: 'delete', label: '삭제', class: 'btn-danger', },
                { event: 'refresh', label: '갱신', class: 'bg-white text-dark', },
                { event: 'status', label: '사용', class: 'bg-white text-dark', options: { enabled: "Y" }, },
                { event: 'status', label: '미사용', class: 'bg-white text-dark', options: { enabled: "N" }, },
                { event: 'regist', label: '추가', class: 'btn-primary', },
            ],
            columns: [ // 테이블 column
                {
                    label: this.$t("계통유형코드"),
                    field: "rowTitle",
                    width: "150px",
                    type: "text",
                    compositionFields: ['sysType', 'sysTypeName'],
                },
                {
                    label: this.$t("설명"),
                    field: "description",
                    type: "text",
                    tdClass: "left-align-wrap",
                },
                {
                    label: this.$t("사용여부"),
                    field: "enabled",
                    width: "100px",
                    type: "text",
                },
            ],
            transCodes: [
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                }
            ],
            tableStyleClass: 'custom-table',
            sysTypeList: null,
        }
    },
    computed: {},
    async created() {
        await this.getDataList();
    },
    mounted() {},
    methods: {
        async handleListButtonClick(event, options) {
            console.log('SystemType handleListButtonClick.', event);

            switch (event) {
                case 'regist':
                    await this.registNew();
                    break;

                case 'delete':
                    await this.deleteChecked();
                    break;

                case 'refresh':
                    await this.getDataList(event);
                    break;

                case 'status':
                    await this.changeStatusChecked(options);
                    break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }

        },

        async handleItemToggle(key, field, status) {
            const that = this;

            console.log('SystemType handleItemToggle :', key, field, status);
            await that.changeStatus(key, field, status);
        },

        async handleItemClick(row, idx, toggle, check) {
            if (toggle || check) return;

            const confirm = await this.$refs['SystemTypeDetail'].notifyCancelEdit();
            if (confirm) {
                this.sysType = row.sysType;
            }
        },

        /**
         * Table List Management Functions
         */
        async getDataList(event) {
            try {
                let result = await backEndApi.sysType.getSysTypeList("Y")
                if (result && result.data) {
                    this.sysTypeList = result.data;
                    if (event) await this.alertNoti("갱신되었습니다.");
                } else {
                    console.error('sysType.getSysTypeList API Failed')
                }
                return result;
            } catch (e) {
                console.error(e);
            }

        },

        async deleteChecked() {
            const that = this;

            try {
                const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                if (!confirm.value) return;

                const checked = this.$refs['table-list'].getCheckedRows();
                if (!that.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        console.log('deleteChecked. sysType :', item.sysType);
                        await backEndApi.sysType.deleteSysType(item.sysType);
                    });
                    await this.alertNoti("선택한 목록을 삭제하였습니다.")
                    let clear = await this.getDataList();
                    if(clear) this.$refs['SystemTypeDetail'].clearData();
                    else this.sysTypeList = null;
                } else {
                    this.alertNoti("목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },

        async changeStatusChecked(options) {
            const that = this;

            let data = options;

            try {
                const checked = this.$refs['table-list'].getCheckedRows();
                if (!that.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        if (!that.isEmpty(data)) {
                            data['sysType'] = item.sysType;

                            if (data.hasOwnProperty('enabled')) {
                                console.log('changeStatusChecked(enabled). data:', data)
                                await backEndApi.sysType.setSysTypeEnabled(data);
                            } else {
                                console.warn('Unknown Status Name')
                            }
                        }
                    });
                    await this.alertNoti("변경하였습니다.");
                    await that.getDataList();
                } else {
                    this.alertNoti("목록을 선택해주세요.")
                }

            } catch (e) {
                console.error(e)
                this.alertNoti("상태 변경에 실패하였습니다.");
            }
        },

        async changeStatus(key, field, status) {
            const data = {};
            data['sysType'] = key;
            data[field] = status;

            try {
                console.log('SysType changeStatus. data:', data)

                let result = await backEndApi.sysType.setSysTypeEnabled(data);
                if (result.data) {
                    await this.alertNoti("변경하였습니다.");
                    await this.getDataList();
                }
            } catch (e) {
                console.error(e)
                this.alertNoti("상태 변경에 실패하였습니다.");
            }
        },

        async registNew() {
            console.log('SysType Regist New One');
            this.$refs.SystemTypeDetail.newData();
            // const data = await this.$refs['SystemTypeDetail'].getData();
            // if (data) {
            //     const comfirm = await this.$refs['SystemTypeDetail'].notifyCancelEdit()
            //     if (!comfirm) return;
            //     else {
            //         await this.$refs['SystemTypeDetail'].clearData()
            //         await this.$refs['SystemTypeDetail'].setData(null);
            //     }
            // } else {
            //     await this.$refs['SystemTypeDetail'].setData(null);
            // }
        },

        // Event Handling

        async handleDetailUpdated(type, data) {
            console.log('SysType handleDetailUpdated. ', type, data);
            //reload
            await this.getDataList();
        },

        handleDetailClosed() {
            this.sysType = null;
        },
    },
}

</script>

<style scoped>
.ld-container {
    height: 90vh;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;

    padding: 10px 30px 10px 20px;
}

.ld-container .list-area {
    height: 100%;
    overflow: hidden;
    padding: 10px;
    /* border-right: 1px solid #ececec; */
}

.ld-container .detail-area {
    /* display: flex; */
    /* flex-direction: column; */
    /* flex-wrap: nowrap; */
    /* flex: 1; */

    height: 100%;    
    margin-right: 00px;
    padding: 0px 10px 0px 10px;
    border-top: 1px solid #f4f4f4;
    border-right: 2px solid #e7e7e7;
    border-bottom: 2px solid #e7e7e7;
    border-left: 1px solid #f4f4f4;
    background-color: #f9f9f9;
    border-radius: 10px;
}

</style>